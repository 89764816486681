import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './HomeManagement.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { AuthContext } from '../Services/AuthContext';
import Alert from '../Alert/Alert'; 

function HomeManagement() {
    const { setProject } = useContext(AuthContext); 
    const navigate = useNavigate();
    const location = useLocation(); 
    const [projects, setProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState(null);
    const [alert, setAlert] = useState({ message: '', type: '', show: false });
    const [showWarning, setShowWarning] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 950) {
                setShowWarning(true);
            } else {
                setShowWarning(false);
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (location.state && location.state.successMessage) {
            setAlert({ message: location.state.successMessage, type: 'success', show: true });
            window.history.replaceState({}, document.title);
        }
    }, [location.state]);

    useEffect(() => {
        fetch('https://webprogress.logixmedia.ch/webprogress/src/WebProgress/Backend/GetProject.php')
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    setProjects(data.projects);
                } else {
                    console.error('Error fetching projects:', data.message);
                }
            })
            .catch(error => {
                console.error('Error fetching projects:', error);
            });
    }, []);

    const handleProjectClick = (project) => {
        setSelectedProject(project);
        setProject(project);
    };

    const handleBackClick = () => {
        setSelectedProject(null);
    };

    const handleViewProjectClick = (project) => {
        navigate(`/gestion_dossier`, { state: { project } }); 
    };

    const handleViewUploadClick = (project) => {
        navigate(`/gestion_contenu`, { state: { project } });
    };

    const handleViewCreateClick = () => {
        navigate(`/gestion_creation_dossier`);
    };

    const handleViewChatClick = (project) => {
        if (project && project.id) {
            setProject(project);
            localStorage.setItem('activeProject', JSON.stringify(project)); 
            navigate(`/discussion/${project.id}`);
        }
    };   
    
    const handleDeleteProjectClick = (project) => {
        if (window.confirm(`Êtes-vous sûr de vouloir supprimer le projet ${project.name} ?`)) {
            fetch('https://webprogress.logixmedia.ch/webprogress/src/WebProgress/Backend/Project.php', { 
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    action: 'delete_project', // Action de suppression envoyée
                    id: project.id // ID du projet à supprimer
                }),
            })
            .then(response => {
                // Si la réponse contient une erreur, la déclencher
                if (!response.ok) {
                    throw new Error('Erreur serveur : ' + response.statusText);
                }
                return response.json(); // Essayer de parser la réponse JSON
            })
            .then(data => {
                if (data.success) {
                    setAlert({ message: 'Projet supprimé avec succès.', type: 'success', show: true });
                    setProjects(prevProjects => prevProjects.filter(p => p.id !== project.id));
                    setSelectedProject(null); // Retour à la liste des projets après suppression
                } else {
                    setAlert({ message: data.message, type: 'error', show: true });
                }
            })
            .catch(error => {
                console.error('Erreur lors de la suppression du projet:', error);
                setAlert({ message: 'Une erreur est survenue lors de la suppression du projet.', type: 'error', show: true });
            });
        }
    };
    
    return (
        <>
            {showWarning ? (
                <div className="no-screen">
                    <p>Votre appareil ou le format de l'écran n'est pas compatible avec cette application. Veuillez utiliser un appareil avec une résolution supérieure à 950px de largeur.</p>
                </div>
            ) : (
                <div className='wrapper-homemanagement'>
                    {alert.show && (
                        <Alert 
                            message={alert.message} 
                            type={alert.type} 
                            onClose={() => setAlert({ message: '', type: '', show: false })} 
                        />
                    )}
                    {!selectedProject && <h1>Gestion des projets</h1>}

                    {!selectedProject ? (
                        <div className='container-homemanagement'>
                            <div className='homemanagement-option' onClick={handleViewCreateClick}>
                                <p>Crée un nouveau projet</p>
                                <button><FontAwesomeIcon icon={faChevronRight} /></button>
                            </div>
                            <hr />
                            {projects.map((project, index) => (
                                <div className='homemanagement-option' key={index} onClick={() => handleProjectClick(project)}>
                                    <p>{project.name}</p>
                                    <button><FontAwesomeIcon icon={faChevronRight} /></button>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div>
                            <button className='back-button' onClick={handleBackClick}><FontAwesomeIcon icon={faArrowLeft} /></button>
                            <h2>{selectedProject.name}</h2>
                            <div className='container-homemanagement'>
                                <div className='homemanagement-option' onClick={() => handleViewProjectClick(selectedProject)}>
                                    <p>Visualiser le dossier</p>
                                    <button><FontAwesomeIcon icon={faChevronRight} /></button>
                                </div>
                                <div className='homemanagement-option' onClick={() => handleViewUploadClick(selectedProject)}>
                                    <p>Contenu du dossier</p>
                                    <button><FontAwesomeIcon icon={faChevronRight} /></button>
                                </div>
                                <div className='homemanagement-option' onClick={() => handleViewChatClick(selectedProject)}>
                                    <p>Discussion du projet</p>
                                    <button><FontAwesomeIcon icon={faChevronRight} /></button>
                                </div>
                                <div className='homemanagement-option' id="delete" onClick={() => handleDeleteProjectClick(selectedProject)}>
                                    <p>Supprimer le projet</p>
                                    <button id='delete-button'><FontAwesomeIcon icon={faChevronRight} /></button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    );
}

export default HomeManagement;
