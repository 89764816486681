import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import './PreviewClient.css';
import Alert from '../Alert/Alert'; 

const formatDate = (dateStr) => {
    if (!dateStr) return 'Date non disponible';
    
    const date = new Date(dateStr);
    const moisAnnee = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
    
    const jourMois = date.getUTCDate();
    const mois = moisAnnee[date.getUTCMonth()];
    const annee = date.getUTCFullYear();
    
    return `${jourMois} ${mois} ${annee}`;
};

function PreviewClient() {
    const location = useLocation();
    const navigate = useNavigate();
    const [project, setProject] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [alert, setAlert] = useState({ message: '', type: '', show: false });
    const iban = "CH76 00767 000C 5538 5540";

    useEffect(() => {
        const fetchProject = async (projectId) => {
            try {
                const response = await fetch(`https://webprogress.logixmedia.ch/webprogress/src/WebProgress/Backend/GetProjectById.php?id=${projectId}`);
                const data = await response.json();
                if (data.success) {
                    setProject(data.project);
                } else {
                    setError('Projet non trouvé');
                }
            } catch (error) {
                setError('Erreur lors de la récupération des données du projet');
            } finally {
                setLoading(false);
            }
        };

        const projectId = location.state?.projectId;
        if (projectId) {
            fetchProject(projectId);
        } else {
            setLoading(false);
            setError('ID de projet non fourni');
        }
    }, [location.state?.projectId]);

    const ReturnHome = () => {
        navigate("/accueil");
    };

    if (loading) {
        return <div>Chargement...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    const formattedAmount = project && project.amount !== undefined ? `${project.amount} CHF` : 'Montant non disponible';

    const calculatePercentage = (amount, percentage) => {
        if (!amount || isNaN(amount)) return 'Montant non disponible';
        return `${(amount * (percentage / 100)).toFixed(2)} CHF`;
    };    

    const startDateFormatted = project?.project_start ? formatDate(project.project_start) : 'Date non disponible';
    const endDateFormatted = project?.project_end ? formatDate(project.project_end) : 'Date non disponible';

    const openInNewTab = (url) => {
        if (url) {
            if (!/^https?:\/\//i.test(url)) {
                url = 'http://' + url;
            }
            window.open(url, '_blank', 'noopener,noreferrer');
        }
    };    

    const handleCopyIban = () => {
        navigator.clipboard.writeText(iban).then(() => {
            setAlert({ message: "IBAN copié avec succès.", type: 'success', show: true });
        }).catch(err => {
            console.error('Erreur lors de la copie de l\'IBAN : ', err);
        });
    };

    return (
        <div className='background-preview'>
            <div className='wrapper-preview'>
                <div className='container-back'>
                    <button onClick={ReturnHome}><FontAwesomeIcon icon={faArrowLeft} /></button>
                </div>
                
                {alert.show && (
                    <Alert 
                        message={alert.message} 
                        type={alert.type} 
                        onClose={() => setAlert({ message: '', type: '', show: false })} 
                    />
                )}
                <h1>Aperçu du dossier</h1>
                <div className='input-box'>
                    <label htmlFor="project-name">Nom du projet</label>
                    <input type="text" id="project-name" name="project-name" value={project?.name || ''} readOnly />
                </div>
                <div className='input-box'>
                    <label htmlFor="folder-id">Numéro de dossier</label>
                    <input type="text" id="folder-id" name="folder-id" value={project?.folder_id || ''} readOnly />
                </div>
                <div className='input-box'>
                    <label htmlFor="project-description">Description du projet</label>
                    <textarea id="project-description" name="project-description" value={project?.description || ''} readOnly></textarea>
                </div>
                <div className='input-box'>
                    <label htmlFor="site-preview">Aperçu du site</label>
                    <input 
                        type="text" 
                        id="site-preview" 
                        name="site-preview" 
                        value={project?.preview_url || ''} 
                        readOnly 
                        onClick={() => openInNewTab(project?.preview_url)}
                        style={{ cursor: 'pointer' }} 
                    />
                </div>
                <h2>Facturation</h2>
                <div className='container-amount'>
                    <div className='input-box'>
                        <label htmlFor="payment-20">Paiement 40%</label>
                        <input
                            type="text"
                            id="payment-20"
                            name="payment-20"
                            value={project ? calculatePercentage(project.amount, 40) : 'Montant non disponible'}
                            readOnly
                        />
                    </div>
                    <div className='input-box'>
                        <label htmlFor="payment-50">Paiement 40%</label>
                        <input
                            type="text"
                            id="payment-50"
                            name="payment-50"
                            value={project ? calculatePercentage(project.amount, 40) : 'Montant non disponible'}
                            readOnly
                        />
                    </div>
                    <div className='input-box'>
                        <label htmlFor="payment-30">Paiement 20%</label>
                        <input
                            type="text"
                            id="payment-30"
                            name="payment-30"
                            value={project ? calculatePercentage(project.amount, 20) : 'Montant non disponible'}
                            readOnly
                        />
                    </div>
                    <div className='input-box'>
                        <label htmlFor="total-amount">Montant total</label>
                        <input
                            type="text"
                            id="total-amount"
                            name="total-amount"
                            value={formattedAmount}
                            readOnly
                        />
                    </div>
                </div>
                <div className='container-iban'>
                    <h4>Coordonnées de l'entreprise</h4>
                    <p>Société: <span>Logixcube – Cornu</span></p>
                    <p>Adresse: <span>Route de Cossonay 15, 1008 Prilly</span></p>
                    <p>Téléphone: <span>+41 76 506 24 24</span></p>
                    <p>E-mail: <span>compta@logixcube.ch</span></p>
                    <p>IBAN: <span>CH76 00767 000C 5538 5540<FontAwesomeIcon className='copy-iban' onClick={handleCopyIban} icon={faCopy} /></span></p>
                </div>
                <h3>Progression</h3>
                <div className='container-date'>
                    <div className='input-box'>
                        <label htmlFor="start-date">Date d'ouverture</label>
                        <input type="text" id="start-date" name="start-date" value={startDateFormatted} readOnly />
                    </div>
                    <div className='input-box'>
                        <label htmlFor="end-date">Date de finalisation</label>
                        <input type="text" id="end-date" name="end-date" value={endDateFormatted} readOnly />
                    </div>
                </div>
                <div className='container-progress'>
                    <p className='progress-text'>Progression du projet</p>
                    <div className='progress-wrapper'>
                        <div className='bar-progress' style={{ width: `${project?.progress || 0}%` }}></div>
                    </div>
                    <p className='progress-percentage'>{project?.progress || 0}%</p>
                </div>
            </div>
        </div>
    );
}

export default PreviewClient;
