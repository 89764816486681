import React, { createContext, useState, useEffect } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [project, setProject] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        const storedProject = localStorage.getItem('project');
        const storedTimestamp = localStorage.getItem('timestamp');
    
        const parsedUser = storedUser ? JSON.parse(storedUser) : null;
        const parsedProject = storedProject ? JSON.parse(storedProject) : null;
        const parsedTimestamp = storedTimestamp ? parseInt(storedTimestamp, 10) : null;
    
        const currentTime = new Date().getTime();
        const sessionDuration = 2 * 60 * 60 * 1000;
    
        if (parsedTimestamp && currentTime - parsedTimestamp < sessionDuration) {
            if (parsedUser) {
                setUser(parsedUser);
            }
            if (parsedProject) {
                setProject(parsedProject);
            }
        } else {
            logout();
        }
    
        setLoading(false);
    }, []);    

    const login = (userData, projectData) => {
        setUser(userData); 
        setProject(projectData);

        const timestamp = new Date().getTime();
        localStorage.setItem('timestamp', timestamp);

        if (userData) {
            localStorage.setItem('user', JSON.stringify(userData));
        } else {
            localStorage.removeItem('user');
        }

        if (projectData) {
            localStorage.setItem('project', JSON.stringify(projectData));
        } else {
            localStorage.removeItem('project');
        }
    };

    const logout = () => {
        setUser(null);
        setProject(null);
        localStorage.removeItem('user');
        localStorage.removeItem('project');
        localStorage.removeItem('timestamp');
        localStorage.removeItem('activeProject'); 
    };

    return (
        <AuthContext.Provider value={{ user, project, setProject, loading, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};
