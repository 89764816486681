import React, { useContext } from 'react';
import './Header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightFromBracket, faUserGroup, faHome } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useLocation } from 'react-router-dom'; 
import Logo from '../Images/favicon.svg';
import { AuthContext } from '../Services/AuthContext'; 

const Header = () => {
    const { user, project, logout } = useContext(AuthContext); 
    const navigate = useNavigate(); 
    const location = useLocation(); 

    const Disconnect = () => {
        logout(); 
        navigate('/login'); 
    };

    const handleAdminButtonClick = () => {
        if (location.pathname === '/gestion_utilisateur') {
            navigate('/gestion'); 
        } else {
            navigate('/gestion_utilisateur'); 
        }
    };

    return (
        <header>
            <ul>
                <div className='container-logo'>
                    <img src={Logo} alt="Logo" />
                </div>
                {(user || project) && ( 
                    <>
                        <button className='disconnect' onClick={Disconnect}>
                            <FontAwesomeIcon icon={faRightFromBracket} />
                        </button>
                        {user && user.role === 'admin' && ( 
                            <button className='admin-management' onClick={handleAdminButtonClick}>
                                <FontAwesomeIcon icon={location.pathname === '/gestion_utilisateur' ? faHome : faUserGroup} />
                            </button>
                        )}
                    </>
                )}
            </ul>
        </header>
    );
};

export default Header;
