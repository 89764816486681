import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Alert from '../Alert/Alert';
import { AuthContext } from '../Services/AuthContext';
import './Connection.css';

function Connection() {
    const [accessCode, setAccessCode] = useState('');
    const [alert, setAlert] = useState(null);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth); 
    const navigate = useNavigate();
    const { login } = useContext(AuthContext);

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleSubmit = () => {
        fetch('https://webprogress.logixmedia.ch/webprogress/src/WebProgress/Backend/Connection.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ code: accessCode }),
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                if (data.user) {
                    if (screenWidth < 950) {
                        setAlert({
                            message: 'Écran non compatible. Utilisez une largeur de minimum 950px.',
                            type: 'error',
                        });
                    } else {
                        login(data.user, null);
                        navigate('/gestion');
                    }
                } else if (data.project) {
                    login(null, data.project);
                    navigate('/accueil');
                }
            } else {
                setAlert({ message: data.message, type: 'error' });
            }
        })
        .catch(error => {
            setAlert({ message: 'Une erreur est survenue. Veuillez réessayer.', type: 'error' });
        });
    };

    return (
        <div className='wrapper-connection'>
            <h1>WebProgress</h1>
            <h2>Créé par Logixcube</h2>
            <div className='input-box'>
                <label htmlFor="access-code">Code d'accès</label>
                <input
                    type="text"
                    id="access-code"
                    value={accessCode}
                    onChange={(e) => setAccessCode(e.target.value)}
                />
            </div>
            <div className='container-btn'>
                <button onClick={handleSubmit}>Continuer</button>
            </div>
            {alert && <Alert message={alert.message} type={alert.type} onClose={() => setAlert(null)} />}
        </div>
    );
}

export default Connection;
