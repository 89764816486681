import React, { useContext, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';

const PrivateRoute = ({ children }) => {
    const { user, project, loading, setProject } = useContext(AuthContext);

    useEffect(() => {
        const storedProject = localStorage.getItem('activeProject');
        if (!project && storedProject) {
            setProject(JSON.parse(storedProject));
        }
    }, [project, setProject]);

    if (loading) {
        return <div>Loading...</div>; 
    }

    if (!user && !project) {
        console.log("Redirection: pas d'utilisateur ou de projet trouvé");
        return <Navigate to="/" />;
    }

    return children;
};

export default PrivateRoute;
