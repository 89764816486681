import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import './PreviewManagement.css';
import Alert from '../Alert/Alert';

const formatDate = (dateStr) => {
    if (!dateStr) return '';

    const date = new Date(dateStr);
    const moisAnnee = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];

    const jourMois = date.getUTCDate();
    const mois = moisAnnee[date.getUTCMonth()];
    const annee = date.getUTCFullYear();

    return `${jourMois} ${mois} ${annee}`;
}

function PreviewManagement() {
    const location = useLocation();
    const navigate = useNavigate();
    const initialProject = location.state?.project || {};
    const [project, setProject] = useState(initialProject);

    const [folderId, setFolderId] = useState(project.folder_id || '');
    const [theme, setTheme] = useState(project.site_theme || '');
    const [colors, setColors] = useState(project.site_colors || '');
    const [audience, setAudience] = useState(project.target_audience || '');
    const [features, setFeatures] = useState(project.features || '');
    const [notes, setNotes] = useState(project.additional_notes || '');
    const [amount, setAmount] = useState(project.amount || '');
    const [previewUrl, setPreviewUrl] = useState(project.preview_url || '');
    const [progress, setProgress] = useState(project.progress || '');
    const [showWarning, setShowWarning] = useState(false); 
    const [alert, setAlert] = useState({ message: '', type: '', show: false });

    const ReturnHome = () => {
        navigate("/gestion");
    };

    const fetchProjectData = (projectId) => {
        fetch(`https://webprogress.logixmedia.ch/webprogress/src/WebProgress/Backend/GetProjectById.php?id=${projectId}`)
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    setProject(data.project);
                    setFolderId(data.project.folder_id || '');
                    setTheme(data.project.site_theme || '');
                    setColors(data.project.site_colors || '');
                    setAudience(data.project.target_audience || '');
                    setFeatures(data.project.features || '');
                    setNotes(data.project.additional_notes || '');
                    setAmount(data.project.amount || '');
                    setPreviewUrl(data.project.preview_url || '');
                    setProgress(data.project.progress || '');
                } else {
                    setAlert({ message: 'Erreur lors de la récupération des données du projet', type: 'error', show: true });
                }
            })
            .catch(error => {
                console.error('Erreur lors de la récupération des données:', error);
                setAlert({ message: 'Erreur lors de la récupération des données du projet', type: 'error', show: true });
            });
    };

    useEffect(() => {
        if (project.id) {
            fetchProjectData(project.id);
        }
    }, [project.id]);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 950) {
                setShowWarning(true);
            } else {
                setShowWarning(false);
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const validateFields = () => {
        if (!folderId) {
            setAlert({ message: 'Le numéro de dossier est requis.', type: 'error', show: true });
            return false;
        }
        if (!theme) {
            setAlert({ message: 'Le thème du site est requis.', type: 'error', show: true });
            return false;
        }
        if (!colors) {
            setAlert({ message: 'Les couleurs principales sont requises.', type: 'error', show: true });
            return false;
        }
        if (!audience) {
            setAlert({ message: 'Le public cible est requis.', type: 'error', show: true });
            return false;
        }
        if (!features) {
            setAlert({ message: 'Les fonctionnalités du site sont requises.', type: 'error', show: true });
            return false;
        }
        if (!amount) {
            setAlert({ message: 'Le montant total est requis.', type: 'error', show: true });
            return false;
        }
        if (!previewUrl) {
            setAlert({ message: 'L\'URL de l\'aperçu du site est requise.', type: 'error', show: true });
            return false;
        }
        return true;
    };

    const handleSave = () => {
        if (!validateFields()) return;

        const updatedProject = {
            id: project.id,
            folder_id: folderId,
            site_theme: theme,
            site_colors: colors,
            target_audience: audience,
            features: features,
            additional_notes: notes,
            amount: amount,
            preview_url: previewUrl,
            progress: progress,
            action: 'update_project' 
        };
    
        fetch(`https://webprogress.logixmedia.ch/webprogress/src/WebProgress/Backend/Project.php`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedProject),
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                setAlert({ message: 'Projet mis à jour avec succès', type: 'success', show: true });
                fetchProjectData(project.id); 
            } else {
                setAlert({ message: 'Erreur lors de la mise à jour du projet', type: 'error', show: true });
            }
        })
        .catch(error => {
            console.error('Erreur lors de la mise à jour:', error);
            setAlert({ message: 'Erreur lors de la mise à jour du projet', type: 'error', show: true });
        });
    };    

    const handleProgressChange = (e) => {
        const newValue = e.target.value;
        setProgress(newValue);

        e.target.style.setProperty('--progress-value', `${newValue}%`);
    };

    const startDateFormatted = formatDate(project.project_start);
    const endDateFormatted = formatDate(project.project_end);

    return (
        <div className='background-preview-management'>
            {showWarning ? (
                <div className="no-screen">
                    <p>Votre appareil ou le format de l'écran n'est pas compatible avec cette application. Veuillez utiliser un appareil avec une résolution supérieure à 950px de largeur.</p>
                </div>
            ) : (
                <div className='wrapper-preview-management'>
                    {alert.show && (
                        <Alert 
                            message={alert.message} 
                            type={alert.type} 
                            onClose={() => setAlert({ message: '', type: '', show: false })} 
                        />
                    )}
                    <div className='container-back'>
                        <button onClick={ReturnHome}><FontAwesomeIcon icon={faArrowLeft} /></button>
                    </div>
                    <h1>Aperçu du dossier</h1>
                    <div className='input-box'>
                        <label htmlFor="project-name">Nom du projet</label>
                        <input type="text" id="project-name" name="project-name" value={project.name || ''} readOnly />
                    </div>
                    <div className='input-box'>
                        <label htmlFor="folder-id">Numéro de dossier</label>
                        <input type="text" id="folder-id" name="folder-id" value={folderId} onChange={(e) => setFolderId(e.target.value)} />
                    </div>
                    <div className='input-box'>
                        <label htmlFor="site-theme">Que sera le thème du site ?</label>
                        <textarea id="site-theme" name="site-theme" value={theme} onChange={(e) => setTheme(e.target.value)}></textarea>
                    </div>
                    <div className='input-box'>
                        <label htmlFor="site-colors">Les couleurs principales à utiliser ?</label>
                        <textarea id="site-colors" name="site-colors" value={colors} onChange={(e) => setColors(e.target.value)}></textarea>
                    </div>
                    <div className='input-box'>
                        <label htmlFor="target-audience">Qui est votre public cible ?</label>
                        <textarea id="target-audience" name="target-audience" value={audience} onChange={(e) => setAudience(e.target.value)}></textarea>
                    </div>
                    <div className='input-box'>
                        <label htmlFor="website-features">Quelles fonctionnalités voulez-vous inclure ?</label>
                        <textarea id="website-features" name="website-features" value={features} onChange={(e) => setFeatures(e.target.value)}></textarea>
                    </div>
                    <div className='input-box'>
                        <label htmlFor="additional-notes">Notes supplémentaires</label>
                        <textarea id="additional-notes" name="additional-notes" value={notes} onChange={(e) => setNotes(e.target.value)}></textarea>
                    </div>
                    <div className='input-box'>
                        <label htmlFor="total-amount">Montant total</label>
                        <input type="text" id="total-amount" name="total-amount" value={amount} onChange={(e) => setAmount(e.target.value)} />
                    </div>
                    <div className='input-box'>
                        <label htmlFor="site-preview">Aperçu du site</label>
                        <input type="text" id="site-preview" name="site-preview" value={previewUrl} onChange={(e) => setPreviewUrl(e.target.value)} />
                    </div>
                    <div className='container-date'>
                        <div className='input-box'>
                            <label htmlFor="start-date">Date d'ouverture</label>
                            <input type="text" id="start-date" name="start-date" value={startDateFormatted} readOnly />
                        </div>
                        <div className='input-box'>
                            <label htmlFor="end-date">Date de finalisation</label>
                            <input type="text" id="end-date" name="end-date" value={endDateFormatted} readOnly />
                        </div>
                    </div>
                    <div className='container-progress'>
                        <p className='progress-text'>Progression du projet</p>
                        <input 
                            type="range" 
                            min="0" 
                            max="100" 
                            value={progress} 
                            onChange={handleProgressChange} 
                            className="progress-slider"
                            style={{ '--progress-value': `${progress}%` }}
                        />
                        <p className='progress-percentage'>{progress}%</p>
                    </div>
                    <div className='container-btn'>
                        <button className='save-btn' onClick={handleSave}>Sauvegarder</button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default PreviewManagement;
