import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Header from './WebProgress/Header/Header';
import Connection from './WebProgress/Connection/Connection';
import NoMatch from './WebProgress/Services/NoMatch';
import HomeClient from './WebProgress/HomeClient/HomeClient';
import PreviewClient from './WebProgress/PreviewClient/PreviewClient';
import UploadClient from './WebProgress/UploadClient/UploadClient';
import Chat from './WebProgress/Chat/Chat';
import HomeManagement from './WebProgress/HomeManagement/HomeManagement';
import PreviewManagement from './WebProgress/PreviewManagement/PreviewManagement';
import PreviewCreate from './WebProgress/PreviewCreate/PreviewCreate';
import UploadManagement from './WebProgress/UploadManagement/UploadManagement';
import UserManagement from './WebProgress/UserManagement/UserManagement';
import { AuthProvider } from './WebProgress/Services/AuthContext';
import PrivateRoute from './WebProgress/Services/PrivateRoute';

const useWindowWidth = () => {
    const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

    React.useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowWidth;
};

const AppContent = () => {
    const windowWidth = useWindowWidth();
    const location = useLocation();

    const hideHeaderRoutes = ['/dossier', '/contenu', '/discussion'];

    const shouldHideHeader = hideHeaderRoutes.some(route => location.pathname.startsWith(route)) && windowWidth < 950;

    return (
        <>
            {!shouldHideHeader && <Header />}
            <Routes>
                <Route path="/" element={<Connection />} />
                <Route path="/accueil" element={<PrivateRoute requiresUser={false}><HomeClient /></PrivateRoute>} />
                <Route path="/dossier" element={<PrivateRoute><PreviewClient /></PrivateRoute>} />
                <Route path="/contenu" element={<PrivateRoute><UploadClient /></PrivateRoute>} />
                <Route path="/discussion/:projectId" element={<PrivateRoute><Chat /></PrivateRoute>} />
                <Route path="/gestion" element={<PrivateRoute requiresUser={true}><HomeManagement /></PrivateRoute>} />
                <Route path="/gestion_dossier" element={<PrivateRoute requiresUser={true}><PreviewManagement /></PrivateRoute>} />
                <Route path="/gestion_creation_dossier" element={<PrivateRoute requiresUser={true}><PreviewCreate /></PrivateRoute>} />
                <Route path="/gestion_contenu" element={<PrivateRoute requiresUser={true}><UploadManagement /></PrivateRoute>} />
                <Route path="/gestion_utilisateur" element={<PrivateRoute requiresUser={true}><UserManagement /></PrivateRoute>} />
                <Route path="*" element={<NoMatch />} />
            </Routes>
        </>
    );
};

function App() {
    return (
        <AuthProvider>
            <Router>
                <AppContent />
            </Router>
        </AuthProvider>
    );
}

export default App;
