import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './PreviewCreate.css';
import Alert from '../Alert/Alert';

const PreviewCreate = () => {
    const navigate = useNavigate();
    const [projectName, setProjectName] = useState('');
    const [folderId, setFolderId] = useState('');
    const [theme, setTheme] = useState('');
    const [colors, setColors] = useState('');
    const [audience, setAudience] = useState('');
    const [features, setFeatures] = useState('');
    const [notes, setNotes] = useState('');
    const [amount, setAmount] = useState('');
    const [email, setEmail] = useState('');
    const [previewUrl, setPreviewUrl] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [progress] = useState(5); 
    const [alert, setAlert] = useState({ message: '', type: '', show: false });
    const [showWarning, setShowWarning] = useState(false); 

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 950) {
                setShowWarning(true);
            } else {
                setShowWarning(false);
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const validateForm = () => {
        if (!projectName) {
            setAlert({ message: 'Le nom du projet est requis.', type: 'error', show: true });
            return false;
        }
        if (!folderId) {
            setAlert({ message: 'Le numéro du dossier est requis.', type: 'error', show: true });
            return false;
        }
        if (!theme) {
            setAlert({ message: 'Le thème du site est requis.', type: 'error', show: true });
            return false;
        }
        if (!colors) {
            setAlert({ message: 'Les couleurs principales sont requises.', type: 'error', show: true });
            return false;
        }
        if (!audience) {
            setAlert({ message: 'Le public cible est requis.', type: 'error', show: true });
            return false;
        }
        if (!features) {
            setAlert({ message: 'Les fonctionnalités sont requises.', type: 'error', show: true });
            return false;
        }
        if (!email) {
            setAlert({ message: 'L\'email est requis.', type: 'error', show: true });
            return false;
        }
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(email)) {
            setAlert({ message: 'L\'email n\'est pas valide.', type: 'error', show: true });
            return false;
        }
        if (!amount) {
            setAlert({ message: 'Le montant total est requis.', type: 'error', show: true });
            return false;
        }
        if (!startDate || !endDate) {
            setAlert({ message: 'Les dates de début et de fin sont requises.', type: 'error', show: true });
            return false;
        }
        const datePattern = /^\d{2}\.\d{2}\.\d{4}$/;
        if (!datePattern.test(startDate) || !datePattern.test(endDate)) {
            setAlert({ message: 'Les dates doivent être au format DD.MM.YYYY.', type: 'error', show: true });
            return false;
        }
        return true;
    };

    const formatInputDate = (value) => {
        const cleaned = value.replace(/\D+/g, '');
        
        const limited = cleaned.substring(0, 8);
        
        let formatted = '';
        if (limited.length > 0) {
            formatted += limited.substring(0, 2);
        }
        if (limited.length >= 3) {
            formatted += '.' + limited.substring(2, 4);
        }
        if (limited.length >= 5) {
            formatted += '.' + limited.substring(4, 8);
        }
        return formatted;
    };

    const handleDateChange = (setter) => (event) => {
        const formattedDate = formatInputDate(event.target.value);
        setter(formattedDate);
    };

    const checkUniqueFields = async () => {
        try {
            const response = await fetch(`https://webprogress.logixmedia.ch/webprogress/src/WebProgress/Backend/Project.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, name: projectName, folder_id: folderId, action: 'check_unique' })
            });
    
            const textResponse = await response.text(); 
    
            const data = JSON.parse(textResponse); 
            if (!data.success) {
                setAlert({ message: data.message, type: 'error', show: true });
                return true;
            }
    
            return false;
        } catch (error) {
            console.error('Erreur lors de la vérification d\'unicité:', error);
            setAlert({ message: 'Erreur lors de la vérification d\'unicité.', type: 'error', show: true });
            return true;
        }
    };
    
    const convertDateToDBFormat = (dateStr) => {
        const parts = dateStr.split('.');
        if (parts.length === 3) {
            const [day, month, year] = parts;
            return `${year}-${month}-${day}`; 
        }
        return ''; 
    };    

    const handleCreateProject = async () => {
        if (!validateForm()) return;
    
        const exists = await checkUniqueFields();
        if (exists) return;
    
        const previewUrlToSave = previewUrl || 'vide';
        const formattedStartDate = convertDateToDBFormat(startDate);
        const formattedEndDate = convertDateToDBFormat(endDate);
    
        const newProject = {
            name: projectName,
            folder_id: folderId,
            site_theme: theme,
            site_colors: colors,
            target_audience: audience,
            features: features,
            additional_notes: notes,
            amount: amount,
            email: email,
            preview_url: previewUrlToSave,
            project_start: formattedStartDate,
            project_end: formattedEndDate,
            progress: progress,
            action: 'create_project'
        };
    
        try {
            const response = await fetch(`https://webprogress.logixmedia.ch/webprogress/src/WebProgress/Backend/Project.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newProject),
            });
    
            if (!response.ok) {
                throw new Error('Failed to create project');
            }
    
            const data = await response.json();
            if (data.success) {
                setAlert({ message: 'Projet créé avec succès', type: 'success', show: true });
                navigate("/gestion", { state: { successMessage: 'Projet créé avec succès' } });
            } else {
                setAlert({ message: data.message, type: 'error', show: true });
            }
        } catch (error) {
            setAlert({ message: 'Erreur lors de la création du projet', type: 'error', show: true });
        }
    };    
    
    return (
        <div className='background-preview-create'>
            {showWarning ? (
                <div className="no-screen">
                    <p>Votre appareil ou le format de l'écran n'est pas compatible avec cette application. Veuillez utiliser un appareil avec une résolution supérieure à 950px de largeur.</p>
                </div>
            ) : (
                <div className='wrapper-preview-create'>
                    {alert.show && (
                        <Alert 
                            message={alert.message} 
                            type={alert.type} 
                            onClose={() => setAlert({ message: '', type: '', show: false })} 
                        />
                    )}
                    <h1>Création du dossier</h1>
                    <div className='input-box'>
                        <label htmlFor="project-name">Nom du projet</label>
                        <input 
                            type="text" 
                            id="project-name" 
                            name="project-name" 
                            placeholder="Entrez le nom du projet"
                            value={projectName} 
                            onChange={(e) => setProjectName(e.target.value)} 
                            maxLength={45} 
                        />
                    </div>
                    <div className='input-box'>
                        <label htmlFor="folder-id">Numéro du dossier</label>
                        <input 
                            type="text" 
                            id="folder-id" 
                            name="folder-id" 
                            placeholder="Entrez le numéro du dossier"
                            value={folderId} 
                            onChange={(e) => setFolderId(e.target.value)} 
                        />
                    </div>
                    <div className='input-box'>
                        <label htmlFor="site-theme">Que sera le thème du site ?</label>
                        <textarea 
                            id="site-theme" 
                            name="site-theme" 
                            placeholder="Entrez le thème du site"
                            value={theme} 
                            onChange={(e) => setTheme(e.target.value)}
                        ></textarea>
                    </div>
                    <div className='input-box'>
                        <label htmlFor="site-colors">Les couleurs principales à utiliser ?</label>
                        <textarea 
                            id="site-colors" 
                            name="site-colors" 
                            placeholder="Entrez les couleurs principales"
                            value={colors} 
                            onChange={(e) => setColors(e.target.value)}
                        ></textarea>
                    </div>
                    <div className='input-box'>
                        <label htmlFor="target-audience">Qui est votre public cible ?</label>
                        <textarea 
                            id="target-audience" 
                            name="target-audience" 
                            placeholder="Entrez le public cible"
                            value={audience} 
                            onChange={(e) => setAudience(e.target.value)}
                        ></textarea>
                    </div>
                    <div className='input-box'>
                        <label htmlFor="website-features">Quelles fonctionnalités voulez-vous inclure ?</label>
                        <textarea 
                            id="website-features" 
                            name="website-features" 
                            placeholder="Entrez les fonctionnalités du site"
                            value={features} 
                            onChange={(e) => setFeatures(e.target.value)}
                        ></textarea>
                    </div>
                    <div className='input-box'>
                        <label htmlFor="additional-notes">Notes supplémentaires</label>
                        <textarea 
                            id="additional-notes" 
                            name="additional-notes" 
                            placeholder="Entrez les notes supplémentaires"
                            value={notes} 
                            onChange={(e) => setNotes(e.target.value)}
                        ></textarea>
                    </div>
                    <div className='input-box'>
                        <label htmlFor="total-amount">Montant total</label>
                        <input 
                            type="text" 
                            id="total-amount" 
                            name="total-amount" 
                            placeholder="Entrez le montant total"
                            value={amount} 
                            onChange={(e) => setAmount(e.target.value)} 
                        />
                    </div>
                    <div className='input-box'>
                        <label htmlFor="email">E-mail du client</label>
                        <input 
                            type="email" 
                            id="email" 
                            name="email" 
                            placeholder="Entrez l'e-mail"
                            value={email} 
                            onChange={(e) => setEmail(e.target.value)} 
                        />
                    </div>
                    <div className='input-box'>
                        <label htmlFor="site-preview">Aperçu du site</label>
                        <input 
                            type="text" 
                            id="site-preview" 
                            name="site-preview" 
                            placeholder="Entrez l'URL de l'aperçu du site"
                            value={previewUrl} 
                            onChange={(e) => setPreviewUrl(e.target.value)} 
                        />
                    </div>
                    <div className='container-date'>
                        <div className='input-box'>
                            <label htmlFor="start-date">Date d'ouverture</label>
                            <input 
                                type="text" 
                                id="start-date" 
                                name="start-date" 
                                placeholder="DD.MM.YYYY"
                                value={startDate} 
                                onChange={handleDateChange(setStartDate)} 
                            />
                        </div>
                        <div className='input-box'>
                            <label htmlFor="end-date">Date de finalisation</label>
                            <input 
                                type="text" 
                                id="end-date" 
                                name="end-date" 
                                placeholder="DD.MM.YYYY"
                                value={endDate} 
                                onChange={handleDateChange(setEndDate)} 
                            />
                        </div>
                    </div>
                    <div className='container-btn'>
                        <button onClick={() => navigate("/gestion")}>Annuler</button>
                        <button onClick={handleCreateProject}>Créer le dossier</button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default PreviewCreate;
