import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './HomeClient.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { AuthContext } from '../Services/AuthContext';

function HomeClient() {
    const { project, setProject } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (!project) {
            const storedProject = localStorage.getItem('activeProject');
            if (storedProject) {
                setProject(JSON.parse(storedProject));
            }
        }
    }, [project, setProject]);

    if (!project) {
        return <div>Chargement...</div>;
    }

    const handleAccessFolder = () => {
        navigate('/dossier', { state: { projectId: project.id } }); 
    };

    const handleAccessUpload = () => {
        navigate('/contenu', { state: { projectId: project.id } }); 
    };

    const handleAccessChat = () => {
        if (project && project.id) {
            navigate(`/discussion/${project.id}`);
        }
    };

    return (
        <div className='wrapper-home'>
            <h1>{project.name}</h1>
            <h2>N° {project.folder_id}</h2>
            <div className='container-home'>
                <div className='home-option' onClick={handleAccessFolder}>
                    <p>Accéder au dossier</p>
                    <button><FontAwesomeIcon icon={faChevronRight} /></button>
                </div>
                <div className='home-option' onClick={handleAccessUpload}>
                    <p>Fournir du contenu</p>
                    <button><FontAwesomeIcon icon={faChevronRight} /></button>
                </div>
                <div className='home-option' onClick={handleAccessChat}>
                    <p>Envoyer un message</p>
                    <button><FontAwesomeIcon icon={faChevronRight} /></button>
                </div>
            </div>
        </div>
    );
}

export default HomeClient;
